import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({ questionColor }) =>
    questionColor ? `& .question { color: ${questionColor};}` : ``}
  ${({ answerColor }) =>
    answerColor ? `& .answer { color: ${answerColor};}` : ``}
  ${({ borderColor }) =>
    borderColor
      ? `& .faqs-item { border-bottom: 1px solid ${borderColor};}`
      : ``}
  ${({ fullWidth }) => (fullWidth ? `max-width: 100%;` : `max-width: 768px;`)}

  &.ingredients {
    
    & .question {
      font-weight: 400;
    }
    & .answer {
      color: #24252B;
    }
    & .faqs-item {
      padding-left: 0;
      padding-right: 0;
    }
    & .icon {
      &:before {
        width: 1px;
        height: 11px;
      }
      &:after {
        height: 1px;
        width: 13px;
        right: -1px;
      }
    }
  }

  &.pdp2023 {
    & .question {
      padding-right: 30px;
    }
    & .icon {
      background: #001C72;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      
      &:before,
      &:after {
        background: #FFF;
      }

      &:before {
        width: 2px;
        height: 12px;
        top: 7px;
        left: 12px;
      }
    
      &:after {
        width: 12px;
        height: 2px;
        top: 12px;
        left: 7px;
      }

      & > div {
        content: '';
        display: block;
        position: absolute;
        border: 6px solid transparent;
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        border-radius: 100%;
        margin-top: -6px;
        margin-left: -6px;
        transition: border 1s;
      }
    
      &.pressed > div {
        border: 6px solid #CCD2E3;
      }
    }
  }
`;

export const UList = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

export const UListItem = styled.div`
  border-bottom: 1px solid #000;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
`;

export const Question = styled.div`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: block;
  padding-top: 3px;

  ${({ plusInLeft }) =>
    plusInLeft ? `padding-left: 20px;` : `padding-right: 20px;`}
`;

export const Answer = styled.div`
  font-size: 16px;
  font-weight: 400;
  &.active {
    display: block;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const Icon = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;

  ${({ plusInLeft }) => (plusInLeft ? `left: 0;` : `right: 0;`)}

  &:before,
  &:after {
    position: absolute;
    content: '';
    transition: transform 0.2s ease-in-out;
    background-color: ${props => props.arrowColor};
    opacity: 1;
  }
  &:before {
    width: 2px;
    height: 12px;
    right: 5px;
    top: 4px;
  }
  &:after {
    width: 12px;
    height: 2px;
    right: 0px;
    top: 9px;
  }
  &.enter-done {
    &:before,
    &:after {
      transform: rotate(90deg);
    }
    :after {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
`;

import React, { useState, useContext, useEffect, useRef } from 'react';
import * as S from './FaqsV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { CSSTransition } from 'react-transition-group';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';

const Faqs = props => {
  const {
    questions,
    questionColor,
    answerColor,
    borderColor,
    arrowColor = '#001c72',
    plusInLeft = false,
    fullWidth = false,
    customStyles = 'default'
  } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const { pageDesign } = useContext(FunnelContext);

  const [checkedOpened, setCheckedOpened] = useState(false);
  const [height, setHeight] = useState(0);

  const refs = useRef(questions.map(() => React.createRef()));

  useEffect(() => {
    let currentHeight =
      activeIndex >= 0 ? refs.current[activeIndex].current.offsetHeight : 0;
    setHeight(currentHeight);
  }, [activeIndex]);

  useEffect(() => {
    if (!checkedOpened) {
      const openedIndex = questions.findIndex(
        question => question.open === true
      );
      setActiveIndex(openedIndex);
      setCheckedOpened(true);
    }
  }, [questions, setActiveIndex, checkedOpened, setCheckedOpened]);

  useButtonEventPressed('faq-press-trigger');

  const handleClick = index => {
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  const duration = 300,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight + 30}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight + 30}px`;
      node.style.marginBottom = `0px`;
    };

  return (
    <S.Container
      className={`faqs-component ${customStyles}`}
      questionColor={questionColor}
      answerColor={answerColor}
      borderColor={borderColor}
      fullWidth={fullWidth}
    >
      <S.UList>
        {questions.map(({ question, answer }, index) => {
          const elHeight = index === activeIndex ? height : 0;

          return (
            <S.UListItem
              key={index.toString()}
              id={`FaqsItem-${index}`}
              className="faqs-item"
              onClick={() => handleClick(index)}
            >
              <S.Question
                className={`${
                  activeIndex === index ? 'active' : ''
                } ${pageDesign} question`}
                plusInLeft={plusInLeft}
              >
                {question}
                <CSSTransition in={activeIndex === index} timeout={0}>
                  <S.Icon
                    arrowColor={arrowColor}
                    plusInLeft={plusInLeft}
                    className="icon faq-press-trigger"
                  >
                    <div></div>
                  </S.Icon>
                </CSSTransition>
              </S.Question>

              <CSSTransition
                in={activeIndex === index}
                timeout={duration}
                classNames="slide"
                unmountOnExit
                onEnter={onEnter}
                onEntering={onEntering}
                onExit={onExit}
                onExiting={onExiting}
              >
                <S.Answer
                  height={elHeight}
                  className={`${activeIndex === index ? 'active' : ''} answer`}
                  dangerouslySetInnerHTML={{ __html: answer }}
                  ref={refs.current[index]}
                />
              </CSSTransition>
            </S.UListItem>
          );
        })}
      </S.UList>
    </S.Container>
  );
};

export default Faqs;
